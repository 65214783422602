<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="pulping-facility-table"
      >
        <header-pulping :table-header="listHeader" />
        <template v-if="loadingTable">
          <loading-table :colspan="7" />
        </template>
        <template v-else-if="listPulping.data && listPulping.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listPulping.data"
              :key="index"
            >
              <td :class="content.vcp_code ? '' : 'uk-text-center'">
                {{ content.vcp_code || '-' }}
              </td>
              <td :class="content.partner_name ? '' : 'uk-text-center'">
                {{ content.partner_name || '-' }}
              </td>
              <td :class="content.batch_id ? '' : 'uk-text-center'">
                {{ content.batch_id || '-' }}
              </td>
              <td :class="content.species ? '' : 'uk-text-center'">
                {{ content.species || '-' }}
              </td>
              <td :class="content.varieties ? '' : 'uk-text-center'">
                {{ content.varieties || '-' }}
              </td>
              <td :class="content.process_type ? '' : 'uk-text-center'">
                {{ content.process_type || '-' }}
              </td>
              <td>{{ content.weight_total || '-' }}</td>
              <td>
                <label-status 
                  :table-value="content.status"
                  :label="inputLabelNumber(content.status)"
                />
              </td>
              <td class="action">
                <a
                  uk-tooltip="View"
                  :class="isCanAccessUser('view', 'PULPING FACILITY') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('view', 'PULPING FACILITY') ? 'ic_eye_table.svg' : 'ic_eye_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'PulpingActivityDetail', params:{ id: content.id }})"
                  >
                </a>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="7"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderPulping from './header.vue'
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import LabelStatus from '@/components/globals/label/label-process/label.vue'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderPulping,
    EmptyTable,
    LoadingTable,
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPulping: {
      required: true,
      type: Object
    },
    listHeader: {
      required: true,
      type: Array
    }
  },
  data(){
    return{
      loadingTable: true
    }
  },
  watch:{
    listPulping(){
      if(this.listPulping.data){
        setTimeout(() =>
          this.loadingTable = false
        ,200)
        this.loadingTable = true
      }
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    inputLabelNumber(s){
      const status = s.toLowerCase()
      if(status.includes("siap")){
        return 2
      } else if(status.includes("sedang")){
        return 1
      }
      return 3
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
