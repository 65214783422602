<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <header-pulping
        :images="images"
        :list-species="listSpecies"
        :list-process="listProcess"
        :list-stages="listPullpingProcessStages"
        :filter-pulping="filterPulping"
        :reset-filter-pulping="resetFilterPulping"
        :meta-filter="metaFilter"
      />
      <content-pulping-activity 
        :images="images"
        :list-pulping="listPulping"
      />
      <pagination
        :total-data="totalData"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="currentPage"
        :current-limit="currentLimit"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import HeaderPulping from './HeaderPulping'
import ContentPulpingActivity from './ContentPulpingActivity'
import Pagination from '@/components/globals/Pagination'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderPulping,
    ContentPulpingActivity,
    Pagination
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listSpecies: 'pulpingActivity/list_species',
      listProcess: 'pulpingActivity/list_process_type',
      listPullpingProcessStages: 'pulpingActivity/list_pulping_process_stages',
      listPulping: 'pulpingActivity/list_pulping',
      metaFilter: 'pulpingActivity/meta_filter'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listPulping(data) {
      this.totalData = data.meta.total
    }
  },
  async mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListPulping: 'pulpingActivity/getListPulping',
      actionGetListFromMasterData: 'pulpingActivity/getListFromMasterData'
    }),
    ...mapMutations({
      setMetaFilter: "pulpingActivity/meta_filter"
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async loadPage() {
      await this.actionGetListFromMasterData({data_type: 'species'})
      await this.actionGetListFromMasterData({data_type: 'process_types'})
      await this.actionGetListFromMasterData({data_type: 'pulping_process_stages'})
      await this.actionGetListPulping({ page: this.currentPage, limit: this.currentLimit})
    },
    async filterPulping() {
      await this.actionGetListPulping({...this.metaFilter, page: 1, limit: 10})
    },
    async resetFilterPulping() {
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetListPulping({ page: this.currentPage, limit: this.currentLimit})
    },
    async changePage(page) {
      this.currentPage = parseInt(page)
      await this.actionGetListPulping({...this.metaFilter, page, limit: this.currentLimit})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      await this.actionGetListPulping({...this.metaFilter, limit: event.target.value, page: 1})
    }
  }
}
</script>
